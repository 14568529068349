import {ChangeDetectorRef, Component, ElementRef, Input, TemplateRef, ViewChild} from '@angular/core';
import {UnsubscribeComponent} from '../../../@core/fc-component';
import {NavigationSectionDirective} from './navigation-section.directive';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

export interface NavigationSectionGroup {
    label: string;
    sections: NavigationSectionDirective[];
    addOnTmp?: TemplateRef<any>[];
}

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent extends UnsubscribeComponent {
    @Input() forceDisplayNavigation = false;
    @Input() hasPagingButtons = false;

    activeSection: NavigationSectionGroup;
    sections: NavigationSectionGroup[] = [];

    private _sections$ = new Subject<NavigationSectionGroup[]>();
    sections$ = this._sections$.pipe(debounceTime(0));

    @ViewChild('navCnt', {static: false}) navCntRef: ElementRef;

    constructor(public elRef: ElementRef, public cdr: ChangeDetectorRef) {
        super();
    }

    jumpTo(section: NavigationSectionGroup) {
    }

    getPrev() {
        const sections = this.sections;
        const i = sections.indexOf(this.activeSection);
        return sections[i - 1] || null;
    }

    getNext() {
        const sections = this.sections;
        const i = sections.indexOf(this.activeSection);
        return sections[i + 1] || null;
    }

    registerSection(section: NavigationSectionDirective) {
        let s = this.sections.find(x => x.label === section.label);

        if (!s) {
            s = {label: section.label, sections: []};
            this.sections.push(s);
        } else if (s.sections.includes(section)) {
            return;
        }

        s.sections.push(section);
        if (section.addOnTmp) {
            if (!s.addOnTmp) s.addOnTmp = [];
            s.addOnTmp.push(section.addOnTmp);
        };
        this._sections$.next(this.sections);
    }

    unregisterSection(section: NavigationSectionDirective) {
        const i = this.sections.findIndex(x => x.sections.includes(section));

        if (i < 0) return;

        const sgs = this.sections[i].sections;
        if (sgs.length > 1) {
            sgs.splice(sgs.indexOf(section), 1);
        } else {
            this.sections.splice(i, 1);
        }
        this._sections$.next(this.sections);
    }

    getNavLinkClass(section: NavigationSectionGroup) {
        const c = [];
        if (section === this.activeSection) c.push('active');
        section.sections.forEach(s => s.navLinkClass && c.push(s.navLinkClass));
        return c.join(' ');
    }

    compOnDestroy() {
        this._sections$.complete();
    }
}
